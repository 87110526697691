/*
 * @公共方法库
 * @author: Jeff Guo
 */
import store from '../store'
import cryptoAES from './cryptoAES'
import { jsonp } from 'vue-jsonp'
import dayjs from 'dayjs'
import OSS from 'ali-oss'
import router from '../router'
// import { getTempUrl } from '../api/index'
import { fileService } from './file-service'

// 判断是否是图片
export const isImage = (url) => {
    return /(jpg|png|gif|jpeg)/i.test(url);
}

/**
 * @description 导出文件
 * @author Jeff.Guo
 * @param { data } 接口请求过来的二进制文件
 * @param { fileName } 自定义下载保存的文件名
 * @param { action } 判断是下载还是预览 download-下载，preview-预览
 * @example this.$utils.exportData(res, new Date()*1 + '.docx')
 */
export const exportData = (data, fileName, action = "download") => {
    if (action === "download") {
        let link = document.createElement('a');
        link.download = fileName;
        link.style.display = 'none';
        let blob = new Blob([data]);
        link.href = URL.createObjectURL(blob);
        console.log(URL.createObjectURL(blob))
        document.body.appendChild(link);
        link.click();
        link.remove();
    } else {
        let blob = new Blob([data], {
            type: 'application/pdf;chartset=UTF-8'
        });
        var fileURL = URL.createObjectURL(blob)
        window.open(fileURL)
    }
}

// 对象转为哈希URL网址
export const queryString = (json) => {
    var url = "";
    if (typeof (json) == 'undefined' || json == null || typeof (json) != 'object') {
        return '';
    }
    for (var k in json) {
        url += ((url.indexOf("=") != -1) ? "&" : "") + k + "=" + json[k];
    }
    return url;
}

// 判断是否为图片
export const isImageType = (str) => {
    // toLowerCase() 将字符串转换为小写，返回一个新的字符串
    const suffix = str.substr(str.lastIndexOf('.') + 1)
    return ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp', 'psd', 'svg', 'tiff'].indexOf(suffix.toLowerCase()) !== -1
}



/**
 * datePicke公共配置
 * @param {*}} str = today ==> 限制当天
 */
export const datePickerOpction = (str) => {
    return {
        disabledDate(date) {
            return date && date.valueOf() > Date.now() - (str === 'today' ? 0 : 86400000);
        }
    }
}

/**
 * 表单校验不通过时，页面自动滚动到第一个验证失败的位置
 */
export const scrollIntoError = (that) => {
    that.$message.warning('请完善信息')
    that.$nextTick(() => {
        let isError = document.getElementsByClassName("is-error")
        isError[0].scrollIntoView({
            block: "center",
            behavior: "smooth",
        })
    })
}

// 生成onlyoffice预览url 提示：除图片格式的文件  data扩展参数
export const previewUrl = async (fileUrl, fileName, data = {}) => {
    const index = fileUrl.lastIndexOf('.')
    const fileType = fileUrl.substr(index + 1)
    const url = await getToOssUrl(fileUrl)
    const query = {
        // userName: store.state.user.userName,现在还没有用户信息暂不取
        fileName: fileName || fileUrl.substr((fileUrl.lastIndexOf('/') + 1)),
        fileUrl: url,
        fileType: fileType,
        ...data
    }
    const i_o = encodeURIComponent(cryptoAES.encrypt(encodeURIComponent(JSON.stringify(query))))
    return `${process.env.VUE_APP_DOC_URL}/CheckDoc?i_o=${i_o}`
}

// 新的接口获取oss地址
export const getToOssUrl = async (url) => {
    // let res = await getTempUrl({
    //     params: {
    //         field: url,
    //     },
    //     config: { Token: router.currentRoute.query.crm_token },
    // })
    const fileUrl = await fileService.getOssFilePublicUrlAsync(url)
    return fileUrl
}

// 服务端编辑保存时，更新状态管理的分数
export const saveGiftedStatus = (order_id) => {
    return new Promise(async (resolve, reject) => {
        try {
            await store.dispatch(
                'gifted/editAction',
                { order_id: Number(order_id), crm_token: router.currentRoute.query.crm_token }
            )
            resolve()
        } catch (error) {
            reject(error)
        }
    })
}

// 服务端编辑保存时，更新状态管理的分数
export const saveHighTalentStatus = (order_id) => {
    return new Promise(async (resolve, reject) => {
        try {
            await store.dispatch(
                'highTalent/editAction',
                { order_id: Number(order_id), crm_token: router.currentRoute.query.crm_token }
            )
            resolve()
        } catch (error) {
            reject(error)
        }
    })
}

/**
 * 获取组件所在目录
 * @param {*} vm 组件实例
 */
export const formatComponentName = (vm) => {
    if (vm.$root === vm) return 'root';
    return vm._isVue && vm.$options && vm.$options.__file ? (vm.$options && vm.$options.__file) : ''
}

/**
 * @param {*} error 完整的错误跟踪，包含 message 和 error stack
 * @param {*} vm 组件错误
 * @param {*} info 特定的错误信息，如生命周期钩子、事件等。
 */
// export const errorHandler = (error, vm, info) => {
//     // 仅在生产环境收集错误信息
//     if(process.env.VUE_APP_ENV !== 'production') return
//     let baseUrl = `https://www.galaxyoversea.com/publicApi/js-error`
//     let params = {
//         pathname: location.pathname, // 事件发起页面路由
//         host: location.host,  // 事件发起页面域名
//         system: "User-Information",   // 系统名
//         err_msg: error.toString(),
//         info,
//         path: formatComponentName(vm), // 定位到对应的组件所在的文件目录
//         useruame: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")).userName : "", // 当前登帐号
//         client_width: screen.width,
//         client_height: screen.height,
//         client_user_agent: navigator.userAgent,
//         _: new Date()*1,
//     }
//     let img = new Image()
//     img.src = `${baseUrl}?${queryString(params)}`
//     img.onload = () => { img.remove() }
// }

/**
 * 发送埋点数据
 * @param {*} json {type}
 * json.type 1 => 手机号，  2 => 邮箱，  3 => 微信ID， 4 => 微信手机号 5 => 身份证号
 * json.type 10 => 浏览，  11 => 点击
 */
export const sendMonitorData = (json) => {
    if (process.env.VUE_APP_ENV !== 'production') return
    let baseUrl = `https://crm.galaxy-immi.com/image/n.gif`;
    let data = {
        ...{
            pathname: location.pathname, // 事件发起页面路由
            host: location.host,  // 事件发起页面域名
            system: "User-Information",   // 系统名
            type: 10,       // 埋点类型
            userName: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")).userName : "", // 当前登帐号
            pageName: "", // 页面名称
            activeName: "",
            value: "",
            _: new Date() * 1
        }, ...json
    }
    let img = new Image();
    img.src = `${baseUrl}?${queryString(data)}`;
    img.onload = () => { img.remove(); }
}

// 清空pdf当前模块的标红
export const emptyModuleRed = (that, className) => {
    that.$nextTick(() => {
        const pdf_user = document.querySelector('.' + className)
        const class_red = pdf_user?.querySelectorAll('.red') || []
        if (class_red.length > 0) {
            class_red.forEach(dom => {
                dom.classList.remove('red')
            })
        }
    })
}

// 获取osskey
export const getOssKey = (vue) => {
    jsonp(process.env.VUE_APP_NODE_URL + '/publicApi/getOssKey').then(res => {
        const date = new Date()
        // const url = `${window.location.origin}`
        const time = `${date.getFullYear()}${(date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1)}${date.getDate()}`
        const md5_key = `filename!@#$%/${time}`
        const md5_encrypt = cryptoAES.MD5(md5_key).substring(8, 24)
        const decryptData = cryptoAES.decrypt(res, md5_encrypt)
        vue.prototype.$OSS = new OSS(JSON.parse(decryptData))
    })
}

// OSS安全跳转链接,加密算法
export const toOss = (url) => {
    if (!url) return;
    // if (url.indexOf('crm.galaxy-immi.com') > -1) return url; // 兼容旧系统,旧系统不做转换
    // if (url.indexOf('galaxy-immi') < 0 && url.indexOf('aliyuncs.com') < 0) return url; // 如果不是OSS地址，不做鉴权
    // let galaxy = '',
    //     env = process.env.VUE_APP_ENV === 'production' ? 'https://serversitemicros.galaxy-immi.com' : process.env.VUE_APP_SERVERSITEMICROS,
    //     path = 'business/common-logic/temp-url',
    //     baseUrl = `${env}/${path}?c=`,
    const token = localStorage.getItem('token')
    if (!token) { // 登录失效
        router.push({
            path: "/login"
        });
    }
    // for (let i = 0; i < url.length; i++) {
    //     galaxy += "galaxy";
    // }
    // return `${baseUrl}${url}&a=-1&b=${cryptoAES.MD5(galaxy + '-1').toString()}`
    const _url = fileService.getOssFilePublicUrlSync(url)
    const protocol = _url.startsWith('http') ? '' : location.protocol
    return protocol + _url
}


// 生存环境神策埋点
export const sensorsData = (vue) => {
    // 埋点
    const sensors = require('sa-sdk-javascript')
    sensors.init({
        server_url: `https://importsensors.galaxy-immi.com/sa?project=${process.env.VUE_APP_ENV === 'production' ? 'default_1' : 'default'}`,
        is_track_single_page: true, // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
        use_client_time: true,
        send_type: 'beacon',
        // show_log: true, // 调试模式可以控制台看埋点数据
        heatmap: {
            //是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
            clickmap: 'default',
            //是否开启触达图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
            scroll_notice_map: 'default'
        }
    })
    // 注册公共属性
    sensors.registerPage({
        platform_type: 'web', // 平台类型
        system_version: '3.0', // 系统版本
        system_mark: 'Galaxy_CRM_Client', // 系统标识
    })
    // 判断用户是否已登录，已登录则把用户id传入神策
    const user = JSON.parse(localStorage.getItem('user'))
    if (user && Object.keys(user).length > 0) {
        sensors.bind("identity_client_user_id", user.userId + "")
        sensors.bind("$identity_mobile", user.phone + "")
    }
    sensors.quick('autoTrack'); //用于采集 $pageview 事件
    sensors.use('PageLeave', {}) // 采集 Web 页面浏览时长
    sensors.use('PageLoad', {}); // 采集 Web 页面加载时长
    // 挂载sensors到vue实例上
    vue.prototype.$sensors = sensors
}

// 根据出生日期算年龄
export const ages = (str) => {
    var r = str.match(/^(\d{1,4})(-|\/)(\d{1,2})\2(\d{1,2})$/);
    if (r == null) return false
    var d = new Date(r[1], r[3] - 1, r[4]);
    if (d.getFullYear() == r[1] && (d.getMonth() + 1) == r[3] && d.getDate() == r[4]) {
        var Y = new Date().getFullYear();
        return Y - r[1]
    }
}

/**  两个数相加 */
export const add = (num1, num2)=> {
    if(!num1 || isNaN(+num1) ) {
        num1 = 0;
    }
    if(!num2 || isNaN(+num2) ) {
        num1 = 0;
    }
    const r1 = (num1.toString().split('.')[1] || '').length;
    const r2 = (num2.toString().split('.')[1] || '').length;
    const m = Math.pow(10, Math.max(r1, r2));
    return (num1 * m + num2 * m) / m;
}

/** 千分位 */
export const formatThousandsSeparator =(number)=> {
    if(!number || isNaN(+number) ) {
        return number;
    }
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

// 函数：安全地将值转换为数字
export const toNumber = (value) => {
    // 如果是 null 或空字符串，转换为 0；如果是其他无效值，返回 0
    return isNaN(Number(value)) ? 0 : Number(value)
}

/**
 * 计算两个日期之间的持续时间
 *
 * @param startTimeStr 开始年月字符串，格式为 "YYYY-MM"
 * @param endTimeStr 结束年月字符串，格式为 "YYYY-MM" 或 "至今"
 * @returns 返回持续时间的字符串，格式为 "X年X月"，如果日期无效或结束时间早于开始时间则返回相应的错误信息
 */
const getDurationInMonths = (startTimeStr, endTimeStr) => {
    if (!startTimeStr || !endTimeStr) return 0;

    const endTimeStrFormatted = endTimeStr === "至今" ? dayjs().format("YYYY-MM") : endTimeStr;
    const startTime = dayjs(startTimeStr + "-01");
    const endTime = dayjs(endTimeStrFormatted + "-01");

    if (!startTime.isValid() || !endTime.isValid()) return 0;
    if (endTime.isBefore(startTime)) return 0;

    return endTime.diff(startTime, 'month');
};

export const calculateDuration = (startTimeStr, endTimeStr) => {
    if (!startTimeStr || !endTimeStr) {
        console.error("缺少参数");
        return "--";
    }
    // 如果结束时间是“至今”，则使用当前年月
    const endTimeStrFormatted = endTimeStr === "至今" ? dayjs().format("YYYY-MM") : endTimeStr;

    // 将年月字符串转换为 dayjs 对象，假设每月1日
    const startTime = dayjs(startTimeStr + "-01");
    const endTime = dayjs(endTimeStrFormatted + "-01");

    // 检查日期是否有效
    if (!startTime.isValid() || !endTime.isValid()) {
        console.error("无效的日期");
        return "--";
    }

    // 确保结束时间晚于开始时间
    if (endTime.isBefore(startTime)) {
        console.error("结束时间早于开始时间");
        return "--";
    }

    // 计算总月数差异
    const totalMonths = endTime.diff(startTime, 'month');

    // 将总月数转换为年和月
    const years = Math.floor(totalMonths / 12);
    const months = totalMonths % 12;

    // 返回结果
    return `${years ? years + "年" : ""}${months ? months + "月" : ""}`;
};

/**
 * 计算多个时间段累计时长
 * @param periods 时间段数组格式 [{start: '开始年月', end: '结束年月'}, ...]
 */
export const calculateTotalDuration = (periods = []) => {
    let totalMonths = 0;

    periods.forEach(p => {
        const months = getDurationInMonths(p.start, p.end);
        totalMonths += months > 0 ? months : 0;
    });

    const years = Math.floor(totalMonths / 12);
    const months = totalMonths % 12;

    return `${years ? years + "年" : ""}${months ? months + "月" : ""}`;
}
